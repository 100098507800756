import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Indent } from "src/uikit"

import ArrowIcon from "./assets/arrow.svg"
import * as S from "./styled"

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <S.Description>{children}</S.Description>
    ),
  },
}

const Content = ({ title, description, linkName }) => (
  <>
    <div className="text-container">
      <S.Title>{title}</S.Title>
      <Indent lg={8} md={8} sm={8} />
      {documentToReactComponents(description, options)}
      <Indent lg={16} md={16} sm={16} />
    </div>
    <S.Button>
      <span>{linkName}</span> <ArrowIcon />
    </S.Button>
  </>
)

const Issue = ({ url, ...props }) => {
  const isExternalLink = url.includes("http")

  return (
    <S.Container>
      {isExternalLink ? (
        <S.Link href={url} target="_blank" rel="noopener noreferrer">
          <Content {...props} />
        </S.Link>
      ) : (
        <S.InnerLink to={url}>
          <Content {...props} />
        </S.InnerLink>
      )}
    </S.Container>
  )
}

export default Issue
