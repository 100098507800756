import styled from "styled-components"

import { media } from "src/uikit"

import bgSrc from "./assets/bg.png"

export const Container = styled.section`
  background-image: url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 0 16px;

  .inner {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .preview-container {
    width: 100%;
    max-width: 800px;
  }

  @media ${media.tablet} {
    .preview-container {
      max-width: 688px;
    }
  }

  @media ${media.mobile} {
    .preview-container {
      max-width: 288px;
    }
  }
`

export const Preview = styled.p`
  margin: 0;
  margin-bottom: 16px;

  font-size: 17px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #0a1c61;

  :last-child {
    margin-bottom: 0;
  }
`

export const QuoteBox = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;

  @media ${media.tablet} {
    max-width: 688px;
  }

  @media ${media.mobile} {
    max-width: 288px;
  }
`

export const QuoteContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 44px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(39, 49, 142, 0.2) 0px 7px 20px 0px;

  @media ${media.tablet} {
    padding: 51px 44px;
  }

  @media ${media.mobile} {
    padding: 24px 16px;
  }
`

export const Quote = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 0.4px;
  color: #142470;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const QuoteText = styled.div`
  margin: 0 32px;
  width: 100%;
  max-width: 477px;
  font-size: 20px;
  line-height: 32px;

  @media ${media.tablet} {
    margin: 0 25px;
  }

  @media ${media.mobile} {
    max-width: 256px;
    margin: 0;
  }
`

export const QuoteAuthor = styled.div`
  text-align: center;
  margin-top: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;

  letter-spacing: 0.5px;

  color: #0a1c61;

  @media ${media.mobile} {
    margin-top: 16px;
  }
`

export const LeftQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: -12px;

  @media ${media.tablet} {
    position: absolute;
    top: 32px;
    left: 30px;
  }

  @media ${media.mobile} {
    top: 16px;
    left: 16px;

    svg {
      height: 14px;
      width: 16px;
    }
  }
`
export const RightQuoteContainer = styled.span`
  flex: 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  top: 12px;

  @media ${media.tablet} {
    position: absolute;
    bottom: 40px;
    right: 32px;
  }

  @media ${media.mobile} {
    bottom: 16px;
    right: 16px;

    svg {
      height: 14px;
      width: 16px;
    }
  }
`
