import React from "react"
import { graphql } from "gatsby"
import { useLocale } from "src/common/hooks"

import { useHeaderColor } from "src/common/hooks"
import Layout from "src/layout"

import Banner from "../components/Banner"
import Features from "../components/Features"
import Quote from "./components/Quote"
import Issues from "./components/Issues"
import UseCases from "../components/UseCases"
import AzureNote from "../components/AzureNote"
import KeyPoints from "../components/KeyPoints"
import {
  CONTACT_MODAL,
  DOWNLOAD_ASSET_MODAL,
  getModalClickHandler,
} from "../../../components/ModalsContainer/events"

const IndustryPage = ({ data, location }) => {
  const locale = useLocale()

  const locales =
    data.allContentfulSolutionsIndustryPage.nodes.find(
      ({ node_locale, quotePreview }) =>
        node_locale === locale && !!quotePreview
    ) || {}

  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: true,
    elem: { selector: "#content", inverse: false },
  })

  const headerColor = {
    primaryColor:
      "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%) #fff",
    secondaryColor: "#fff",
  }

  const handleCtaClick = locales.ctaDownloadAssetModal
    ? getModalClickHandler(DOWNLOAD_ASSET_MODAL, {
        modalId: locales.ctaDownloadAssetModal.modalId,
      })
    : getModalClickHandler(CONTACT_MODAL)

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
    >
      <Banner
        json={locales.solutionKeyPointsMainBody.json}
        image={locales.mainImage}
        ctaBtnName={locales.ctaButtonName}
        onCtaClick={handleCtaClick}
      />
      <Features
        json={locales.featuresBlock.json}
        ctaBtnName={locales.featuresCtaBtnName}
        onCtaClick={handleCtaClick}
      />
      <Quote
        previewJson={locales.quotePreview.json}
        quote={locales.quoteText}
        author={locales.quoteAuthor}
      />
      <Issues
        title={locales.issuesHeading}
        posts={locales.issuesBlock || []}
        linkName={locales.postLinkName}
        ctaBtnName={locales.featuresCtaBtnName}
        onCtaClick={handleCtaClick}
      />
      <UseCases
        title={locales.heading2}
        json={locales.keyPoints2.json}
        ctaBtnName={locales.useCasesCtaButtonName}
        ctaBtnUrl={locales.useCasesCtaUrl}
        ctaBtnModalType={locales.useCasesCtaModalType}
        ctaBtnModalTitle={locales.useCasesCtaModalTitle}
      />
      <AzureNote json={locales.solutionHighlightedNote.json} />
      <KeyPoints
        title={locales.heading3}
        json={locales.mainBody3.json}
        btnName={locales.cta3}
        btnUrl={locales.cta3Url}
        btnModalType={locales.cta3ModalType}
        btnModalTitle={locales.cta3ModalTitle}
      />
    </Layout>
  )
}

export default IndustryPage

export const query = graphql`
  query($slug: String!) {
    allContentfulSolutionsIndustryPage(filter: { pageId: { eq: $slug } }) {
      nodes {
        pageId
        node_locale
        cta3
        cta3Url
        cta3ModalType
        cta3ModalTitle
        ctaDownloadAssetModal {
          modalId
        }
        ctaButtonName
        featuresBlock {
          json
        }
        featuresCtaBtnName
        issuesBlock {
          blogPostId
          bpIntroduction {
            json
          }
          title
        }
        postLinkName
        issuesHeading
        quotePreview {
          json
        }
        quoteText
        quoteAuthor
        heading2
        heading3
        keyPoints2 {
          json
        }
        mainBody3 {
          json
        }
        mainImage {
          file {
            url
          }
          description
        }
        solutionHighlightedNote {
          json
        }
        solutionKeyPointsMainBody {
          json
        }
        useCasesCtaButtonName
        useCasesCtaUrl
        useCasesCtaModalType
        useCasesCtaModalTitle
      }
    }
  }
`
