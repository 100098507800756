import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import LeftQuote from "src/images/company/leftQuote.svg"
import RightQuote from "src/images/company/rightQuote.svg"
import { Indent } from "src/uikit"

import * as S from "./styled"

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => <S.Preview>{children}</S.Preview>,
  },
}

const Quote = ({ previewJson, quote, author }) => {
  return (
    <S.Container>
      <div className="inner">
        <Indent lg={140} md={120} sm={110} />
        <div className="preview-container">
          {documentToReactComponents(previewJson, options)}
        </div>
        <Indent lg={40} md={40} sm={40} />
        <S.QuoteBox>
          <S.QuoteContainer>
            <S.Quote>
              <S.LeftQuoteContainer>
                <LeftQuote />
              </S.LeftQuoteContainer>
              <S.QuoteText>{quote}</S.QuoteText>
              <S.RightQuoteContainer>
                <RightQuote />
              </S.RightQuoteContainer>
            </S.Quote>
            <S.QuoteAuthor>{author}</S.QuoteAuthor>
          </S.QuoteContainer>
        </S.QuoteBox>
        <Indent lg={107} md={92} sm={72} />
      </div>
    </S.Container>
  )
}

export default Quote
