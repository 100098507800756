import styled from "styled-components"

import { Title, media } from "src/uikit"

export const Container = styled.section`
  padding: 0 16px;

  .inner {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .issues-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 2;
  }

  .issue-container {
    margin: 0 32px 56px 32px;
  }

  ${Title} {
    width: 100%;
    max-width: 1000px;

    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #0a1c61;
  }

  .attachment-container {
    z-index: 2;
  }

  @media ${media.tablet} {
    .issue-container {
      margin: 0 20px 40px 20px;
    }

    ${Title} {
      max-width: 688px;
    }
  }

  @media ${media.mobile} {
    .issues-container {
      flex-direction: column;
    }

    .issue-container {
      margin-left: 0;
      margin-right: 0;
    }

    .attachment-container {
      width: 100%;
    }

    ${Title} {
      max-width: 288px;

      font-size: 26px;
      line-height: 32px;
    }
  }
`
