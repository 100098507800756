import styled, { css } from "styled-components"
import { Link as GLink } from "gatsby"

import { media } from "src/uikit"

export const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #0a1c61;
  transition: 0.3s;
  text-shadow: 0px -2px 0 white, 0px -1px 0 white, 0px 0px 0 white,
    2px -2px 0 white, 2px -1px 0 white, 2px 0px 0 white, -2px -2px 0 white,
    -2px -1px 0 white, -2px 0px 0 white, 1px -2px 0 white, 1px -1px 0 white,
    1px 0px 0 white, -1px -2px 0 white, -1px -1px 0 white, -1px 0px 0 white,
    0px -2px 0 white, 0px -1px 0 white, 0px 0px 0 white;
  box-shadow: 0 -1px 0 0 white inset, 0 -3px 0 0 transparent inset;
  display: inline;
`

export const Container = styled.div`
  width: 100%;
  max-width: 357px;

  .text-container {
    :hover {
      ${Title} {
        color: #5855f4;
        box-shadow: 0 -1px 0 0 white inset, 0 -3px 0 0 #5855f4 inset;
      }
    }
  }

  @media ${media.tablet} {
    max-width: 324px;
  }

  @media ${media.mobile} {
    max-width: 288px;
  }
`

export const Description = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #0a1c61;
`

export const Button = styled.span`
  display: flex;
  align-items: center;

  transition: 0.3s;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: underline;
  color: #0a1c61;

  :hover {
    color: #5855f4;

    svg {
      path {
        fill: #5855f4;
      }
    }
  }

  svg {
    margin-left: 8px;

    path {
      transition: 0.3s;
    }
  }
`

const linkStyle = css`
  outline: none;
  text-decoration: none;
`

export const Link = styled.a`
  ${linkStyle}
`

export const InnerLink = styled(GLink)`
  ${linkStyle}
`
